import PropTypes from 'prop-types'
import React from 'react'
import pic01 from '../images/hdr_nanohd2_960x320.jpg'
import pic02 from '../images/hdr_switch_960x320.jpg'
import pic03 from '../images/hdr_rb_960x320.jpg'
import pic04 from '../images/hdr_nanohd_720x320.jpg'
import pic05 from '../images/hdr_nanohd3_960x320.jpg'
import pic06 from '../images/hdr_acm_720x320.jpg'
import pic07 from '../images/hdr_achd_960x320.jpg'
import pic08 from '../images/hdr_nanohd4_720x320.jpg'
import pic09 from '../images/hdr_switch_960x320.jpg'
import pic10 from '../images/hdr_uck_720x320.jpg'

class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="intro"
          className={`${this.props.article === 'intro' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">What We Do</h2>
          <span className="image main">
            <img src={pic01} alt="" />
          </span>
          <h3>Welcome!</h3>
          <p>
            Lelantos is a full-service WiFi solution provider. We cater
            particularly to residences with unique needs such as
            work-from-home studies, home studios, shop-houses, tuition and
            work-shops. We also have expertise with large homes, particularly
            penthouses and landed properties with large outdoor areas.
          </p>
          <p>
            Besides our area of focus we also provide more general solutions
            for any type of residential or commercial space: from dormitories
            to HDB units, offices to clinics. Call us whether you are
            renovating and would like a quote; or facing intermittent trouble,
            we will be able to help!
          </p>
          <h3>WiFi problems at home?</h3>
          <p>
            Your Internet subscription typically comes with everything you
            need to get started - including a WiFi router. If you live in a
            small space, or have it ideally setup so that you work near the
            router, you probably do not need our help! Unlike with offices,
            the equipment provided for home use may be sufficient for your
            needs.
          </p>
          <p>
            However if you live in a slightly larger space or perhaps have
            the Fibre Termination Point in your home located in a corner; or
            are perhaps renovating and would like to properly distribute WiFi
            coverage evenly throughout your home, we can help.
          </p>
          <p>
            It may also be that you now work a lot more from home, and the
            equipment when installed was situated for entertainment in the
            living room rather than your study. Or perhaps over time you
            are starting to notice frequent sound drop outs during your
            video conferences, poor video quality or just poor coverage
            insufficient for your work needs.
          </p>
          <h3>What we do</h3>
          <p>
            We bring our experts in to evaluate your current situation and
            needs, identify ideal equipment placement based on your
            environment and supply high-quality yet unobtrusive equipment
            to be installed either by your contractors or ourselves.
          </p>
          <p>
            <blockquote>
              Good WiFi is primarily three things: High-quality
              equipment, good coverage and proper set up.
            </blockquote>
          </p>
          <h3>Equipment</h3>
          <p>
            We supply professional and enterprise-grade products from
            {' '}<a href="https://www.ui.com/">Ubiquiti</a>,
            {' '}<a href="https://mikrotik.com/">MikroTik</a> and
            {' '}<a href="https://www.netgate.com/">Netgate</a>. "Enterprise"
            products are usually used by business customers in malls and
            offices. These commercial customers require features that
            are also important for many home users, particulary those who 
            are working or running a business from home. These can be 
            features such as:
          </p>
          <p>
            <ul>
              <li>
                <strong>Robustness</strong> - Built for reliability, to be used 24x7
                commercially where downtime and frequent resets are
                not tolerated.
              </li>
              <li>
                <strong>Multiple Networks</strong> - Allowing separation of
                work, home and guest traffic.
              </li>
              <li>
                <strong>Advanced Firewalls</strong> - Options to block and throttle
                undesirable traffic.
              </li>
              <li>
                <strong>Tunable Radios</strong> - Allows setting and tuning of frequency
                bands for optimum performance.
              </li>
              <li>
                <strong>Low Maintenance</strong> - Automatic and tested updates,
                self-monitoring and alerts.
              </li>
              <li>
                <strong>Unobtrusive Design</strong> - Blends into most environments,
                unlike home gaming router.
              </li>
              <li>
                <strong>Roaming</strong> - Made for congested office wifi environments
                where people also move around.
              </li>
            </ul>
          </p>
          <h3>Our Service</h3>
          <p>
            It is not enough to buy good equipment - our service is what
            ensures the equipment performs in your environment. We begin
            every engagement by understanding your needs and performing
            an on-site assessment. This ensures that we can recommend
            complete solutions for good coverage.
          </p>
          <p>
            We then follow-up with the equipment installation and
            commissioning; this covers burn in testing, configuration
            and support for the devices that you will be using. <em>We
            do not just install equipment on-site - we ensure that it works
            for you!</em>
          </p>
          <h3>Affordable Value</h3>
          <p>
            There is a general opinion that enterprise-grade equipment and
            bespoke consulting is more expensive, and indeed we are not cheap.
            However, our experience and business model allow us to offer
            affordable solutions, and we focus on ensuring that you get
            what you pay for in terms of high performance, no-nonsense
            solutions.
          </p>
          <p>
            We offer our service in two models: WiFi as a Service
            subscriptions / managed-site contracts 
            as well as bespoke "Design and Build" solutions for any space. Both
            models include our full suite of service and equipment.
          </p>
          <span className="image main">
            <img src={pic06} alt="" />
          </span>
          {close}
        </article>

        <article
          id="work"
          className={`${this.props.article === 'work' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">What We Do</h2>
          <span className="image main">
            <img src={pic02} alt="" />
          </span>
          <h3>Overview</h3>
          <p>
            We are a one-stop solution for residential, home/office and
            commercial WiFi and Internet connectivity. This page has
            information on our specialised yet affordable "Design and Build"
            WiFi solutions, what to expect and how to engage us.
          </p>
          <h3>What do you provide?</h3>
          <p>
            Everything from survey to hardware; this typically includes
            consulting, equipment, installation, commissioning, documentation
            and support.
          </p>
          <h3>How does it work?</h3>
          <p>
            Customers typically come to us with a problem: poor
            video conference quality or weak signal for smart home devices,
            or an opportunity; perhaps an upcoming home renovation or
            desire to offer connectivity to their guests.
          </p>
          <p>
            Upon receipt of your enquiry we respond to schedule a
            no-obligation email exchange or phone call, sometimes followed
            by a video conference. This helps us understand your needs as
            well as your current wifi environment. During this call we will
            suggest some options and give you some idea of pricing.
          </p>
          <h3>What are your consulting costs?</h3>
          <p>
            Should you wish to proceed we will schedule a site visit where
            our consultant will do an on-site survey, discuss your
            expectations and provide a report with recommendations. We
            charge $250 for this consultation and survey.
          </p>
          <p>
            As part of this consultation we will provide a detailed quotation
            and recommendation, but we are also happy to discuss
            alternative options and self-install solutions. We may also have
            advise for improving your current set up, and in some cases
            (typically with misconfiguration) we are able to solve WiFi
            issues on the spot.
          </p>
          <p>
            After the visit we will followup with a quote for a full-service
            installation so that you may consider your options.
          </p>
          <h3>What does it cost?</h3>
          <p>
             Several options will be presented in the quotation. Total
             costs typically depend on your environment and expectation
             of coverage and throughput - for instance, do you need high
             bandwidth, full signal coverage in the closets and laundry
             area?
          </p>
          <p>
             The most common type of installation is for apartments -
             and these typically run in the $2,000 range, costing less
             (excluding your own contractor works) if you are already
             renovating, and significantly more for high-bandwidth coverage
             to every corner of a multi-level penthouse unit.
          </p>
          <p>
             Landed homes vary significantly and a landed homeowner can
             expect to spend from $2,000 upwards just for WiFi; and typically
             around $5,000 on a 3+ storey terraced home under renovation for
             WiFi coverage and wired LAN in every room. Full-coverage of a
             large bungalow with surrounding outdoor land can cost $20,000
             and beyond, ultimately depending on size and complexity.
          </p>
          <h3>What to expect during the engagement</h3>
          <p>
             We start with floor plans and move on to site visits; We scan
             the WiFi environment to allow consideration for neighbouring
             signals, assess your structural layout, work and living spaces,
             then make recommendations that take into account aesthetic
             considerations.
          </p>
          <p>
             We prefer to work with your contractors during a renovation,
             but can also bring our own for minor surface works. Prior to the
             actual installation we conduct a full equipment test and burn-in,
             followed by an on-site infrastructure test before the actual
             physical installation.
          <p>
          </p>
             We then conduct a full test with you and your devices to
             demonstrate the coverage and speed, as well as the simplicity of
             connecting more devices.
          </p>
          <p>
             Our systems are low-maintenance, upgrade automatically and will
             likely not require your attention ever. Nonetheless we provide
             documentation and basic training so you're able to add new
             devices, troubleshoot and provide specific information should
             you run into issues.
          </p>
          <p>
             We also provide direct on-site warranty support options,
             including options for next-day on-site equipment exchange.
          </p>
          <span className="image main">
            <img src={pic08} alt="" />
          </span>
          {close}
        </article>

        <article
          id="about"
          className={`${this.props.article === 'about' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">About</h2>
          <span className="image main">
            <img src={pic03} alt="" />
          </span>
          <h3>
            Hello!
          </h3>
          <p>
            I founded Lelantos after 25 years of helping people get their 
            computers networked. What initially started as a hobby became a 
            side-gig, then a few jobs in telecoms/technology, eventually 
            professional network consulting, partnerships and a business.
          </p>
          <p>
            My staff and I have a wide experience across various networking 
            use cases but are primarily focussed in high-quality (high 
            throughput, low latency and supporting many simultaneous users) 
            WiFi. We have worked on small and large projects, both permanent 
            and temporary, on fixed as well as mobile platforms and even on 
            city-wide solutions. Our forte is high performing yet discreet  
            solutions in bespoke floor plans, such as penthouses and landed 
            residences with multiple floors, outdoor areas, below-ground 
            or detached structures.
          </p>
          <p>
            We are friendly but highly opinionated and we believe in delivering 
            the best solution within a reasonable budget. We are also transparent 
            with our clients: it does not matter if you prefer an in-depth 
            discussion on solutions and capabilities or a "just get it done" 
            vendor you can trust to provide the most suitable solution at a fair 
            price - either way we only recommend solutions that we would ourselves 
            would be happy to use everyday.
          </p>
          <p>
            The photos you see throughout these pages are examples of real-life
            installations that we have deployed. While there are many 
            good looking photos of wifi equipment on our site, it's the magic of 
            seamless connectivity and ongoing reliability that we provide. WiFi 
            shouldn't be something you have to think about every day!
          </p>
          <p>
            Get in touch with us through our Enquiry page. We look
            forward to combining awesome products with our expertise in
            computer networking and experience across many different spaces
            to come up with affordable solutions that will make your WiFi
            experience seamless again.
          </p>
          <p>
             <b>Alvin</b><br/>
             Founder
          </p>
          <span className="image main">
            <img src={pic10} alt="" />
          </span>
          {close}
        </article>

        <article
          id="enquire"
          className={`${this.props.article === 'enquire' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">ENQUIRE NOW</h2>
          <form method="post" netlify-honeypot="bot-field" data-netlify="true" action="/" name="contact">
              <input type="hidden" name="bot-field" />
              <input type="hidden" name="form-name" value="contact" />
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input type="text" name="name" id="name" />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="text" name="email" id="email" />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea name="message" id="message" rows="4"></textarea>
            </div>
            <ul className="actions">
              <li>
                <input type="submit" value="Send Message" className="special" />
              </li>
              <li>
                <input type="reset" value="Reset" />
              </li>
            </ul>
          </form>
          {/*
          <ul className="icons">
            <li>
              <a
                href="https://twitter.com/"
                className="icon fa-twitter"
              >
                <span className="label">Twitter</span>
              </a>
            </li>
            <li>
              <a href="https://codebushi.com" className="icon fa-facebook">
                <span className="label">Facebook</span>
              </a>
            </li>
            <li>
              <a href="https://codebushi.com" className="icon fa-instagram">
                <span className="label">Instagram</span>
              </a>
            </li>
            <li>
              <a
                href="https://github.com/codebushi/gatsby-starter-dimension"
                className="icon fa-github"
              >
                <span className="label">GitHub</span>
              </a>
            </li>
          </ul>
          */}
          <p>
             You could also drop us an email: <a href="mailto: hello@lelantos.org">hello@lelantos.org</a>
          </p>
          <span className="image main">
            <img src={pic07} alt="" />
          </span>
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
